import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import "../assets/_navigation.scss";
import { AuthContext } from "../context/authContext";
import SearchBar from "./Search";

export default function Navigation() {
  const { isDarkMode, currentUser, logout } = useContext(AuthContext);
  return (
    <main
      style={{
        backgroundColor: isDarkMode ? "#808080" : "#fff", // 动态背景色
        color: isDarkMode ? "#fff" : "#000",
      }}
    >
      <nav
        className="site-navigation"
        style={{
          backgroundColor: isDarkMode ? "#355070" : "#d8e2dc", // 动态背景色
          color: isDarkMode ? "#fff" : "#000",
        }}
      >
        <Link className="tit" to="/">
          Post <div className="red">&nbsp;From CPMORES</div>
        </Link>
        <SearchBar />
        <div className="links">
          {!currentUser && (
            <Link to="/login" className="link">
              Login
            </Link>
          )}
          {currentUser && <p className="link">{currentUser.username}</p>}
          <span className="link">|</span>
          <Link to="/register" className="link">
            Register
          </Link>
          <span className="link">|</span>
          {currentUser && (
            <span className="link" onClick={logout}>
              Logout
            </span>
          )}
        </div>
      </nav>
      <navbar
        style={{
          backgroundColor: isDarkMode ? "#6d597a" : "#f4acb7", // 动态背景色
          color: isDarkMode ? "#fff" : "#000",
        }}
      >
        <div className="links">
          <Link to="/home" className="link">
            Home
          </Link>
          <span className="link">|</span>
          <Link to="/home?cat=movie" className="link">
            Movie
          </Link>
          <span className="link">|</span>
          <Link to="/home?cat=web" className="link">
            Web
          </Link>
          <span className="link">|</span>
          <Link to="/home?cat=writeup" className="link">
            Writeup
          </Link>
          <span className="link">|</span>
          <Link to="/home?cat=poems" className="link">
            Poems
          </Link>
          <span className="link">|</span>
          <Link to="/home?cat=note" className="link">
            Note
          </Link>
          <span className="link">|</span>
          <Link to="/home?cat=math" className="link">
            Math
          </Link>
          <span className="link">|</span>
          <Link to="/tools" className="link">
            Tools
          </Link>
          <span className="link">|</span>
          <Link to="/friend" className="link">
            Friends
          </Link>
        </div>
        <div className="writes">
          <Link className="write" to="/write">
            Write
          </Link>
        </div>
      </navbar>
    </main>
  );
}
