import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../assets/CalendarDesign.scss"; // 自定义样式

export default function MyCalendar() {
  const [date, setDate] = useState(new Date());

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  return (
    <div className="calendar-container">
      <Calendar
        onChange={handleDateChange}
        value={date}
        tileClassName={({ date, view }) => {
          // 可自定义日期格样式
          if (view === "month" && date.getDay() === 6) {
            return null; // 高亮显示周六
          }
          return null;
        }}
        tileContent={null} // 标注事件
      />
      <div className="selected-date">
        <p>Date chosen: {date.toDateString()}</p>
      </div>
    </div>
  );
}
