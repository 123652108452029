import { React, useState, useEffect, useContext } from "react";
import "../assets/single.scss";
import { useNavigate, useLocation } from "react-router-dom";
import MdEditor from "../components/MdEditor";
import Menu from "../components/Menu.js";
import axios from "axios";
import moment from "moment";
import { notification, Spin, FloatButton, Switch } from "antd";
import { AuthContext } from "../context/authContext.js";
import { LoadingOutlined } from "@ant-design/icons";

axios.defaults.withCredentials = true;

export default function Single() {
  const [post, setPosts] = useState(null);
  const loc = useLocation();
  const postId = loc.pathname.split("/")[2];
  const { currentUser } = useContext(AuthContext);

  const { isDarkMode, setIsDarkMode } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://my-blog-cpmores-gpvl.vercel.app/api/posts/${postId}`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://my-blog-cpmores-gpvl.vercel.app/api/posts/${postId}`
      );
      navigate("/home");
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = () => {
    if (currentUser && currentUser.id === post?.uid) {
      navigate(`/write?edit=${postId}`, {
        state: { post: post },
      });
    } else {
      notification.open({
        message: "Warning",
        description: "You are not authorized to edit this post.",
        type: "warning",
        placement: "topRight",
      });
    }
  };

  const handleClick2 = () => {
    if (currentUser && currentUser.id === post?.uid) {
      handleDelete();
    } else {
      notification.open({
        message: "Warning",
        description: "You are not authorized to delete this post.",
        type: "warning",
        placement: "topRight",
      });
    }
  };
  const handleModeChange = () => {
    setIsDarkMode(!isDarkMode);
  };
  return (
    <div
      style={{
        backgroundColor: isDarkMode ? "#355070" : "#ffe5d9", // 动态背景色
        color: isDarkMode ? "#fff" : "#000",
      }}
    >
      <Switch
        checkedChildren="Dark"
        unCheckedChildren="Light"
        onChange={handleModeChange}
        style={{ position: "fixed", bottom: 10, left: 10 }} // Switch 的位置
      />
      <div className="single">
        <div className="content">
          {!post ? (
            <Spin
              style={{
                height: "70vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
            />
          ) : (
            <>
              <img src={post?.img} alt="Post" />
              <div className="information">
                <div className="user">
                  <img src={post?.userImg} alt="User Avatar" />
                  <div className="info">
                    <span className="name">{post.username}</span>
                    <p>Posted {moment(post.date).fromNow()}</p>
                  </div>
                </div>
                <div className="edit">
                  <button className="editing" onClick={handleClick}>
                    Edit
                  </button>
                  <span className="delete" onClick={handleClick2}>
                    Delete
                  </span>
                </div>
              </div>

              <article>
                <h1 style={{ fontSize: "35px" }}>{post.title}</h1>
                <MdEditor content={post.desc} />
              </article>
            </>
          )}
        </div>

        <div className="menu">
          <Menu cat={post?.cat} />
        </div>
      </div>
      <FloatButton.BackTop />
    </div>
  );
}
